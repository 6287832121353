// Username regular expression:
// https://stackoverflow.com/questions/12018245/regular-expression-to-validate-username
// 4 to 24 characters.
// Must begin with a letter.
// Letters, numbers, underscores, hyphens allowed.
export const USER_REGEX = /^[A-z][A-z0-9-_]{1,23}$/;
export const USER_REGEX_DESCRIPTION =
  "2 to 24 characters. Must begin with a letter. Letters, numbers, underscores, hyphens allowed.";

// Password regular expression:
// https://stackoverflow.com/questions/19605150/regex-for-password-must-contain-at-least-eight-characters-at-least-one-number-a
// 8 to 24 characters.
// Must include uppercase and lowercase letters, a number and a special character.
// Allowed special characters: !@#
// export const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{7,24}$/;
export const PWD_REGEX = /^[A-z0-9-_!@#$%]{6,24}$/;
export const PWD_REGEX_DESCRIPTION =
  "7 to 24 characters. Uppercase and Lowercase letters, a number and a special character _!@# allowed.";

// Email regular expression: https://www.w3resource.com/javascript/form/email-validation.php
export const EMAIL_REGEX =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
export const PHONE_REGEX = /^[\d\s\+\-\(\)\/\.]{1,20}$/; // eslint-disable-line no-useless-escape
export const STORE_GROCERY_REGEX =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-][a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-\s]{0,23}$/;
