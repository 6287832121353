/* eslint-disable */
import { createContext, useState, useMemo } from 'react';
// secureLocalStorage fails in test. I use local storage only for test
import secureLocalStorage from 'react-secure-storage';
import useFriendsCtx from '../hooks/useFriendsCtx';
import useStoresCtx from '../hooks/useStoresCtx';

const AuthContext = createContext({});

export function AuthProvider({ children }) {
  const { initStores } = useStoresCtx();
  const { initFriends, initOpenRequests } = useFriendsCtx();

  const [authState, setAuthState] = useState({
    authenticated: false,
    email: '',
    username: '',
    id: 0,
    profile_image: null,
  });

  const logout = () => {
    // secureLocalStorage fails in test. I use local storage only for test
    secureLocalStorage.clear();
    // localStorage.clear();
    setAuthState({
      authenticated: false,
      email: '',
      username: '',
      id: 0,
      profile_image: null,
    });
    // remove all Store Data & Friends Data
    initStores();
    initFriends();
    initOpenRequests();
  };

  const value = useMemo(
    () => ({
      authState,
      setAuthState,
      logout,
    }),
    [authState]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export default AuthContext;
