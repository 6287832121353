import {
  Container,
  Typography,
  Card,
  Grid,
  CardHeader,
  CardContent,
  Avatar,
  Box,
} from "@mui/material";
import Colors from "../../constants/Colors";

export default function Testimonials() {
  return (
    <Box
      sx={{
        backgroundColor: Colors.brightBeige,
        backgroundImage: `url(${process.env.PUBLIC_URL}/bg.svg)`,
        backgroundRepeat: "no-repeat",
      }}
    >
      <Container
        sx={{
          paddingBottom: 8,
        }}
        id="Testimonials"
      >
        <Box textAlign="center" sx={{ pt: 11, pb: 5 }}>
          <Typography variant="h2" sx={{ color: Colors.gray_1 }}>
            Testimonials
          </Typography>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4}>
            <Card
              elevation={0}
              sx={{
                borderRadius: "16px",
                minHeight: { xs: "auto", sm: "235px" }, // Apply different minHeight based on the breakpoint
              }}
            >
              <CardHeader
                avatar={
                  <Avatar
                    sx={{ bgcolor: Colors.error100 }}
                    aria-label="testimonials"
                    src="/people/man1.jpg"
                  />
                }
                title="Ethan Montgomery"
                titleTypographyProps={{
                  variant: "h5",
                  style: { fontWeight: "bold" },
                }}
              />
              <CardContent>
                <Typography variant="p" color="text.secondary">
                  Simple, intuitive and makes grocery shopping a breeze. Best
                  app for shared lists!
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Card
              elevation={0}
              sx={{
                borderRadius: "16px",
                minHeight: { xs: "auto", sm: "235px" }, // Apply different minHeight based on the breakpoint
              }}
            >
              <CardHeader
                avatar={
                  <Avatar
                    sx={{ bgcolor: Colors.error100 }}
                    src="/people/man2.jpg"
                    aria-label="testimonials"
                  />
                }
                title="Harrison Blake"
                titleTypographyProps={{
                  variant: "h5",
                  style: { fontWeight: "bold" },
                }}
              />
              <CardContent>
                <Typography variant="p" color="text.secondary">
                  Remarkably efficient in solving a common household issue!
                  Sharing and syncing lists with family and friends has never
                  been more streamlined or user-friendly.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Card
              elevation={0}
              sx={{
                borderRadius: "16px",
                minHeight: { xs: "auto", sm: "235px" }, // Apply different minHeight based on the breakpoint
              }}
            >
              <CardHeader
                avatar={
                  <Avatar
                    sx={{ bgcolor: Colors.error100 }}
                    aria-label="people"
                    src="/people/woman1.jpg"
                  />
                }
                title='Isabella "Bella" Harper'
                titleTypographyProps={{
                  variant: "h5",
                  style: { fontWeight: "bold" },
                }}
              />
              <CardContent>
                <Typography variant="p" color="text.secondary">
                  Revolutionized my shopping! Sharing lists never been easier.
                  Family&apos;s in sync now.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Card
              elevation={0}
              sx={{
                borderRadius: "16px",
                minHeight: { xs: "auto", sm: "255px" }, // Apply different minHeight based on the breakpoint
              }}
            >
              <CardHeader
                avatar={
                  <Avatar
                    sx={{ bgcolor: Colors.error100 }}
                    src="/people/woman3.jpg"
                    aria-label="testimonials"
                  />
                }
                title="Zoe Madeline"
                titleTypographyProps={{
                  variant: "h5",
                  style: { fontWeight: "bold" },
                }}
              />
              <CardContent>
                <Typography variant="p" color="text.secondary">
                  This app makes shopping a team effort. Minor bugs, but highly
                  efficient.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Card
              elevation={0}
              sx={{
                borderRadius: "16px",
                minHeight: { xs: "auto", sm: "255px" }, // Apply different minHeight based on the breakpoint
              }}
            >
              <CardHeader
                avatar={
                  <Avatar
                    sx={{ bgcolor: Colors.error100 }}
                    src="/people/man5.jpg"
                    aria-label="people"
                  />
                }
                title='Leonard "Leo" Fletcher'
                titleTypographyProps={{
                  variant: "h5",
                  style: { fontWeight: "bold" },
                }}
              />
              <CardContent>
                <Typography variant="p" color="text.secondary">
                  Transforms grocery shopping into a collaborative task.
                  Brilliant for sharing lists with friends and family.
                  User-friendly interface with minor bugs that are largely
                  offset by its practicality.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Card
              elevation={0}
              sx={{
                borderRadius: "16px",
                minHeight: { xs: "auto", sm: "255px" }, // Apply different minHeight based on the breakpoint
              }}
            >
              <CardHeader
                avatar={
                  <Avatar
                    sx={{ bgcolor: Colors.error100 }}
                    src="/people/woman4.jpg"
                    aria-label="testimonials"
                  />
                }
                title="Laurel Bennett"
                titleTypographyProps={{
                  variant: "h5",
                  style: { fontWeight: "bold" },
                }}
              />
              <CardContent>
                <Typography variant="p" color="text.secondary">
                  It&apos;s practical and user-friendly. Makes grocery shopping
                  less of a chore. Love it!
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
