// import * as React from "react";
import { useState, useEffect, useRef } from "react";
import { alpha } from "@mui/system"; // import the alpha utility from MUI
import {
  Box,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Slide,
  useScrollTrigger,
  Container,
  Drawer,
  // Button,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import ClearIcon from "@mui/icons-material/Clear";
import Colors from "../../constants/Colors";

function HideOnScroll(props) {
  const { children } = props;
  const trigger = useScrollTrigger();

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

const pages = ["Home", "Testimonials", "Contact", "About"];
// const settings = ["Profile", "Account", "Dashboard", "Logout"];

function NavBarMaterial() {
  const [mobileNavOpen, setMobileNavOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [scrollDirection, setScrollDirection] = useState(null);
  const appBarRef = useRef(null);
  let lastScrollY = window.scrollY;

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;

      if (currentScrollY > lastScrollY) {
        setScrollDirection("down");
      } else if (currentScrollY < lastScrollY) {
        setScrollDirection("up");
      }

      setIsScrolled(currentScrollY > 0);
      lastScrollY = currentScrollY;
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (appBarRef.current) {
      if ((isScrolled && scrollDirection === "up") || isHovered) {
        if (lastScrollY > 60 || isHovered) {
          appBarRef.current.style.backgroundColor = Colors.brightBeige;
          appBarRef.current.style.transition = "background-color 0.5s";
        } else {
          appBarRef.current.style.backgroundColor = alpha(
            Colors.brightBeige,
            0
          );
          appBarRef.current.style.transition = "background-color 0.5s";
        }
      } else {
        appBarRef.current.style.backgroundColor = alpha(Colors.brightBeige, 0);
        appBarRef.current.style.transition = "background-color 0.5s";
      }
    }
  }, [isScrolled, isHovered, scrollDirection]);

  const handleOpenMobileNav = () => {
    setMobileNavOpen(true);
  };

  const handleCloseMobileNav = (page) => {
    setMobileNavOpen(false);
    if (page === "Home" && appBarRef.current) {
      appBarRef.current.style.backgroundColor = alpha(Colors.brightBeige, 0);
      appBarRef.current.style.transition = "background-color 1.5s";
    } else if (window.scrollY < 100) {
      appBarRef.current.style.backgroundColor = alpha(Colors.brightBeige, 0);
      appBarRef.current.style.transition = "background-color 1.5s";
    }
  };

  return (
    <HideOnScroll>
      <AppBar
        position="fixed"
        component="nav"
        ref={appBarRef}
        sx={{
          zIndex: 1300,
          "& *": {
            color: Colors.gray0,
          },
        }}
        elevation={0}
        onMouseEnter={() => setIsHovered(true)} // Add mouse enter event
        onMouseLeave={() => setIsHovered(false)} // Add mouse leave event
      >
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Box
              sx={{
                display: { xs: "none", md: "flex" },
              }}
            >
              <img
                src="/logo_horizontal.png"
                alt="Grocery Quest"
                className="w-40  ml-0 mt-0 md:mt-0"
              />
            </Box>
            {/* <Typography
              variant="h6"
              noWrap
              component="a"
              href="/"
              sx={{
                mr: 2,
                display: { xs: "none", md: "flex" },
                fontFamily: "monospace",
                fontWeight: 700,
                letterSpacing: ".3rem",
                color: "inherit",
                textDecoration: "none",
              }}
            >
              LOGO
            </Typography> */}
            <Box
              sx={{
                mr: 2,
                display: { xs: "flex", md: "none" },
                color: "inherit",
              }}
              component="a"
              href=""
            >
              <img
                src="/logo_horizontal.png"
                alt="Free Grocery List App"
                className="w-40  ml-0 mt-0 md:mt-0"
              />
            </Box>
            <Box
              sx={{
                flexGrow: 1,
                display: { xs: "flex", md: "none" },
                justifyContent: "flex-end",
              }}
            >
              {!mobileNavOpen && (
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleOpenMobileNav}
                  color="inherit"
                >
                  <MenuIcon />
                </IconButton>
              )}
              {mobileNavOpen && (
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleCloseMobileNav}
                  color="inherit"
                >
                  <ClearIcon />
                </IconButton>
              )}
              <Drawer
                anchor="top"
                open={mobileNavOpen}
                onClose={handleCloseMobileNav}
                PaperProps={{
                  style: {
                    backgroundColor: Colors.brightBeige, // your preferred color
                  },
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    mt: 9,
                    textAlign: "center",
                    flexGrow: 0,
                  }}
                >
                  <ul
                    style={{
                      // display: "flex",
                      justifyContent: "center",
                      listStyle: "none",
                      padding: 0,
                      margin: 0,
                    }}
                  >
                    {/* menu for mobile screen */}
                    {pages.map((page) => (
                      <li key={page} style={{ margin: "24px 10px " }}>
                        <Typography variant="a">
                          <a
                            href={`#${page}`}
                            onClick={() => handleCloseMobileNav(page)}
                          >
                            {page}
                          </a>
                        </Typography>
                      </li>
                    ))}
                  </ul>
                </Box>
              </Drawer>
            </Box>

            <Box
              sx={{
                flexGrow: 1,
                display: { xs: "none", md: "flex" },
              }}
            >
              <Box
                sx={{
                  flexGrow: 1,
                  display: { xs: "none", md: "flex" },
                  justifyContent: "flex-end",
                }}
              >
                <ul
                  style={{
                    display: "flex",
                    listStyle: "none",
                    padding: 0,
                    margin: 0,
                  }}
                >
                  {/* menu for bigger screen */}
                  {pages.map((page) => (
                    <li key={page} style={{ margin: "0 10px" }}>
                      <Typography variant="a">
                        <a href={`#${page}`}>{page}</a>
                      </Typography>
                    </li>
                  ))}
                </ul>
              </Box>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </HideOnScroll>
  );
}
export default NavBarMaterial;
