import { useState, useEffect } from "react";
import {
  Container,
  Box,
  Grid,
  Button,
  TextField,
  Typography,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import { BeatLoader } from "react-spinners";
import { EMAIL_REGEX, USER_REGEX } from "../../constants/regex";
import { CONTACT_URL } from "../../constants/network";
import useAxiosCtx from "../../hooks/useAxiosCtx";

import Colors from "../../constants/Colors";

export default function Contact() {
  const [loading, setLoading] = useState(false);
  const [enteredName, setEnteredName] = useState("");
  const [enteredEmail, setEnteredEmail] = useState("");
  const [enteredMessage, setEnteredMessage] = useState("");
  const [isValidName, setIsValidName] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [isValidMessage, setIsValidMessage] = useState(false);
  const [doneSubmit, setDoneSubmit] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [nameError, setNameError] = useState("");
  const [messageError, setMessageError] = useState("");
  const [sentFormMessage, setSentFormMessage] = useState("");

  const { publicAxios } = useAxiosCtx();

  useEffect(() => {
    setIsValidEmail(EMAIL_REGEX.test(enteredEmail));
  }, [enteredEmail]);

  useEffect(() => {
    setIsValidName(USER_REGEX.test(enteredName));
  }, [enteredName]);

  useEffect(() => {
    setIsValidMessage(enteredMessage.length > 0);
  }, [enteredMessage]);

  const nameChecker = () => {
    if (isValidName || enteredName.length === 0) {
      setNameError("");
    } else {
      setNameError("Enter valid name");
    }
  };

  const emailChecker = () => {
    if (isValidEmail || enteredEmail.length === 0) {
      setEmailError("");
    } else {
      setEmailError("Enter valid email");
    }
  };

  const messageChecker = () => {
    if (isValidMessage || enteredMessage.length === 0) {
      setMessageError("");
    } else {
      setMessageError("Enter valid message");
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!isValidEmail) {
      setEmailError("Enter valid email");
      return;
    }

    if (!isValidName) {
      setNameError("Enter valid name");
      return;
    }

    if (!isValidMessage) {
      setMessageError("Enter valid message");
      return;
    }

    setLoading(true);
    setEmailError("");
    setNameError("");

    try {
      const response = await publicAxios.post(CONTACT_URL, {
        email: enteredEmail,
        name: enteredName,
        message: `gqshop: ${enteredMessage}`,
      });

      setLoading(false);
      setDoneSubmit(true);
      setSentFormMessage(
        "Message sent successfully! Thank you for reaching out."
      );
      console.log(response);
    } catch (error) {
      let errMsg = "Message failed to send. ";
      if (error && error.message) {
        errMsg += error.message;
      }
      // alert(errMsg);
      setDoneSubmit(true);
      setSentFormMessage(errMsg);
    }
  };

  return (
    <Box sx={{ backgroundColor: Colors.orange0 }}>
      <Container id="Contact">
        <Box textAlign="center" sx={{ pt: 11, pb: 5 }}>
          <Typography variant="h2" sx={{ color: Colors.white }}>
            Contact
          </Typography>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Box>
              <img
                src="/happy_bag.jpg"
                alt="Happy Shopping bag, because the shopping is done"
                style={{ width: "90%", height: "auto" }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            {/* <!-- Contact Form --> */}
            {/* <form > */}
            {!doneSubmit ? (
              <Box
                component="form"
                sx={{
                  "& > :not(style)": { mb: 1.5, width: "100%" },
                  pb: 8,
                }}
                noValidate
                autoComplete="off"
                onSubmit={handleSubmit}
              >
                <TextField
                  id="name"
                  label="Name"
                  variant="outlined"
                  autoComplete="off"
                  required
                  onChange={(e) => setEnteredName(e.target.value)}
                  onBlur={nameChecker}
                  error={nameError !== ""}
                  helperText={nameError !== "" ? nameError : " "}
                  value={enteredName}
                  InputProps={{
                    style: { backgroundColor: "white" },
                  }}
                />
                <TextField
                  id="email"
                  label="Email"
                  variant="outlined"
                  autoComplete="off"
                  required
                  onChange={(e) => setEnteredEmail(e.target.value)}
                  onBlur={emailChecker}
                  error={emailError !== ""}
                  helperText={emailError !== "" ? emailError : " "}
                  value={enteredEmail}
                  InputProps={{
                    style: { backgroundColor: "white" },
                  }}
                />
                <TextField
                  id="message"
                  label="Message"
                  autoComplete="off"
                  multiline
                  rows={4}
                  placeholder=""
                  onChange={(e) => setEnteredMessage(e.target.value)}
                  onBlur={messageChecker}
                  value={enteredMessage}
                  required
                  error={messageError !== ""}
                  helperText={messageError !== "" ? messageError : " "}
                  InputProps={{
                    style: { backgroundColor: "white" },
                  }}
                />
                <Button
                  type="submit" // This is to make sure the button submits the form when clicked
                  disabled={loading}
                  variant="contained"
                  endIcon={<SendIcon />}
                  sx={{
                    borderRadius: "20px",
                    color: Colors.white,
                    fontWeight: "bold",
                    backgroundColor: Colors.gray0,
                  }}
                  style={{ boxShadow: "none" }}
                >
                  {!loading ? "Submit" : <BeatLoader color="#fff" loading />}
                </Button>
              </Box>
            ) : (
              <Box
                sx={{
                  "& > :not(style)": { mt: 3, mb: 1.5, width: "100%" },
                  pb: 8,
                }}
              >
                <Typography variant="p" sx={{ color: Colors.white }}>
                  {sentFormMessage}
                </Typography>
              </Box>
            )}
            {/* </form> */}
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
