import NavBarMaterial from "./NavBarMaterial";
import Main from "./Main";
import Testimonials from "./Testimonials";
import Contact from "./Contact";
import Footer from "./Footer";

export default function Marketing() {
  return (
    <>
      <NavBarMaterial />
      <Main />
      <Testimonials />
      <Contact />
      <Footer />
    </>
  );
}
