import { Container, Box, Typography } from "@mui/material";
import Colors from "../../constants/Colors";

export default function Footer() {
  return (
    <Box sx={{ backgroundColor: Colors.green0 }}>
      <Container id="About">
        <Box sx={{ pt: 11, pb: 11 }}>
          <Typography
            variant="h3"
            sx={{
              color: Colors.white,
              fontSize: { xs: "1.2rem", sm: "1.2rem", md: "1.3rem" },
              marginBottom: 1,
            }}
          >
            Grocery Quest
          </Typography>
          <Typography variant="p" sx={{ color: Colors.white }}>
            &copy; 2023 Gotchaping LLC. <br />
            All Rights Reserved.
          </Typography>
        </Box>
      </Container>
    </Box>
  );
}
