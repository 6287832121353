import React from "react";
import "./index.css";
import { createRoot } from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import App from "./App";
import { AuthProvider } from "./contexts/AuthProvider";
import StoresContextProvider from "./contexts/StoresContextProvider";
import FriendsContextProvider from "./contexts/FriendsContextProvider";
import { AxiosProvider } from "./contexts/AxiosProvider";
import Colors from "./constants/Colors";

const root = createRoot(document.getElementById("root"));

const colorTheme = createTheme({
  palette: {
    primary: {
      light: "#ff0000",
      main: Colors.darkSeaGreen,
      dark: Colors.turqoiseGreen,
      contrastText: "#fff",
    },
    secondary: {
      light: "#ff7961",
      main: Colors.princetonOrange,
      dark: "#ba000d",
      contrastText: "#000",
    },
    gray: {
      light: "#ff0000",
      main: Colors.gray,
      dark: "#ba0000",
      contrastText: "#00f",
    },
    pale: {
      light: "#ff0000",
      main: Colors.paleGray,
      dark: "#ba0000",
      contrastText: "#fff",
    },
  },
  typography: {
    fontFamily: ['"Inter var"', "Open Sans"].join(","),
    h1: {
      fontSize: "1.8rem",
      fontWeight: "bold",
    },
    h2: {
      fontSize: "1.5rem",
      fontWeight: "bold",
    },
    h3: {
      fontSize: "1.5rem",
      fontWeight: "bold",
    },
    h4: {
      fontSize: "1.2rem",
      fontWeight: "bold",
    },
    h5: {
      fontSize: "0.9rem",
      fontWeight: "bold",
      color: Colors.gray0,
    },
    a: {
      fontWeight: "bold",
      fontSize: "0.9rem",
      color: Colors.gray0,
    },
    p: {
      fontSize: "0.9rem",
    },
  },
});

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <AxiosProvider>
        <StoresContextProvider>
          <FriendsContextProvider>
            <AuthProvider>
              <ThemeProvider theme={colorTheme}>
                <Routes>
                  <Route path="/*" element={<App />} />
                </Routes>
              </ThemeProvider>
            </AuthProvider>
          </FriendsContextProvider>
        </StoresContextProvider>
      </AxiosProvider>
    </BrowserRouter>
  </React.StrictMode>
);
