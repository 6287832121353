// export const BASE_URL = "http://127.0.0.1:8000/";
// export const BASE_URL = 'http://8.tcp.ngrok.io:12173/';
export const BASE_URL = "https://gqshop.online/";
// export const WS_BASE_URL = "ws://127.0.0.1:8000/";
// export const WS_BASE_URL = 'ws://8.tcp.ngrok.io:12173/';
export const WS_BASE_URL = "wss://gqshop.online/";
export const WS_NOTIFICATION_URL = "notification/";
export const LOGIN_URL = "api/user/login/";
export const REGISTER_URL = "api/user/register/";
export const STORES_URL = "api/stores/";
export const COMPLETED_STORES_URL = "api/stores/completed/";
export const ADD_GROCERY_URL = "api/stores/grocery";
export const GROCERY_URL = "api/stores/grocery/";
export const REFRESH_URL = "api/user/token-refresh/";
export const PASSWORD_RESET_REQUEST_URL = "api/user/request-password-reset/";
export const PASSWORD_RESET_COMPLETE_URL = "api/user/complete-password-reset/";
export const PASSWORD_RESET_REDIRECT_URL = "http://localhost:3000";
export const OTP_VERIFY_URL = "api/user/otp-verify/";
export const OTP_PASSWORD_RESET_REQUEST_URL =
  "api/user/otp-request-password-reset/";
export const OTP_PASSWORD_RESET_COMPLETE_URL =
  "api/user/otp-complete-password-reset/";
export const PRIVACY_POLICY = "api/user/update-privacy-policy/";
export const FRIENDS_LIST_URL = "api/friends/";
export const FRIENDS_SEARCH_URL = "api/friends/user_search/";
export const REQUEST_STATUS_URL = "api/friends/request_status/";
export const FRIEND_REQUEST_SEND_URL = "/api/friends/request";
export const FRIEND_REQUEST_UPDATE_URL = "/api/friends/request_update";
export const FRIEND_OPEN_REQUEST_URL = "/api/friends/open_requests";
export const PROFILE_IMAGE_UPLOAD_URL = "api/profile/";
export const CONTACT_URL = "api/user/web-contact-form/";
// about Expo Linking: https://docs.expo.dev/guides/linking/
// Custom builds: myapp://
// Development in Expo Go: exp://127.0.0.1:19000
// Published app in Expo Go: exp://u.expo.dev/[project-id]?channel-name=[channel-name]&runtime-version=[runtime-version]
