import { Container, Grid, Box, Typography, Button } from "@mui/material";
import Colors from "../../constants/Colors";

export default function Main() {
  return (
    <Box
      sx={{
        backgroundColor: Colors.white,
        backgroundImage: `url(${process.env.PUBLIC_URL}/background_half.jpg)`,
        backgroundRepeat: "repeat-x",
        backgroundPosition: "center bottom",
        paddingTop: 8,
        paddingBottom: 4,
      }}
    >
      <Container id="Home" sx={{ paddingTop: 1 }}>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            sm={6}
            sx={{
              // backgroundColor: Colors.gray0,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                // backgroundColor: Colors.red,
                minHeight: { xs: "auto", sm: "300px", md: "auto" }, // Apply different minHeight based on the breakpoint
              }}
            >
              <Typography
                variant="h1"
                sx={{ color: Colors.green0, marginBottom: 1 }}
              >
                Grocery Quest
              </Typography>
              <Typography
                variant="h1"
                sx={{ color: Colors.gray0, marginBottom: 1 }}
              >
                List-n-Share!
              </Typography>
              <Box>
                <Typography
                  variant="p"
                  sx={{
                    color: Colors.gray0,
                    marginBottom: 1,
                  }}
                >
                  Create, customize and share shopping lists, ensuring you and
                  your family never forget a grocery item again.
                </Typography>
              </Box>
              <Button
                variant="contained"
                color="secondary"
                sx={{
                  borderRadius: "20px",
                  color: Colors.white,
                  fontWeight: "bold",
                  marginY: 2,
                  border: "3px solid white",
                }}
                style={{ boxShadow: "none" }}
              >
                Get Started
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box>
              <img
                src="/phone.png"
                alt="Grocery Quest App Capture"
                style={{ width: "100%", height: "auto" }}
              />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
