/* eslint-disable */
// import Register from "./screens/auth/Register";
// import Login from "./screens/auth/Login";
import Marketing from "./screens/marketing/Marketing";
// import PasswordResetRequest from "./screens/auth/PasswordResetRequest";
// import PasswordResetPWInput from "./screens/auth/PasswordResetPWInput";
// import OTPVerify from "./screens/auth/OTPVerify";
// import Contact from "./screens/auth/Contact";
// import StoresLayout from "./screens/stores/StoresLayout";
// import StoresList from "./screens/stores/StoresList/StoresList";
// import StoreDetail from "./screens/stores/StoreDetail/StoreDetail";
// import AddStore from "./screens/stores/AddStore";
// import AddGroceries from "./screens/stores/AddGroceries";
// import AddShares from "./screens/stores/AddShares";
// import CompletedStoreDetail from "./screens/stores/CompletedStoreDetail/CompletedStoreDetail";
// import CompletedStoresList from "./screens/stores/CompletedStoresList/CompletedStoresList";
import AllowAnyLayout from "./screens/AllowAnyLayout";
import Missing from "./screens/Missing";
// import Unauthorized from "./screens/Unauthorized";
// import LinkPage from "./screens/LinkPage";
// import RequireAuth from "./screens/RequireAuth";
// import Profile from "./screens/account/Profile";
// import ProfileImage from "./screens/account/ProfileImage";
// import FriendsSearch from "./screens/account/FriendsSearch";
// //import FriendsSearch from './screens/account/FriendsSearch';
// import APIErrorModal from "./components/APIErrorModal";
import { Routes, Route } from "react-router-dom";

// All file structure is from  https://youtu.be/oUZjO00NkhY

function App() {
  return (
    <Routes>
      <Route path="/" element={<AllowAnyLayout />}>
        {/* public routes */}
        <Route path="/" element={<Marketing />} />
        {/* <Route path="login" element={<Login />} />
        <Route path="register" element={<Register />} />
        <Route path="contact" element={<Contact />} />
        <Route
          path="password_reset_request"
          element={<PasswordResetRequest />}
        />
        <Route
          path="password_reset_pwinput"
          element={<PasswordResetPWInput />}
        />
        <Route path="otp_verify" element={<OTPVerify />} />
        <Route
          path="api/user/validate-password-reset/:uidb64/:token/"
          element={<PasswordResetPWInput />}
        />
        <Route path="linkpage" element={<LinkPage />} />
        <Route path="unauthorized" element={<Unauthorized />} /> */}

        {/* we want to protect these routes */}
        {/* <Route element={<RequireAuth />}> */}
        {/* <Route element={<StoresLayout />}>
            <Route path="/" element={<StoresList />}>
              <Route path="add_store" element={<AddStore />} />
              <Route path="api_error" element={<APIErrorModal />} />
            </Route>

            <Route path="/store/:storeId/" element={<StoreDetail />}>
              <Route path="add_groceries/" element={<AddGroceries />} />
              <Route path="add_shares/" element={<AddShares />} />
              <Route path="api_error/" element={<APIErrorModal />} />
            </Route> */}
        {/* https://dev.to/devmdmamun/create-contextual-modal-navigation-with-react-router-v6-28k2 */}
        {/* React Router Moldal  Tutorial */}
        {/* <Route path="/account/" element={<Profile />}>
              <Route path="profile_image/:userId/" element={<ProfileImage />} />
              <Route path="friends_search" element={<FriendsSearch />} />
              <Route path="api_error" element={<ProfileImage />} />
            </Route>
            <Route path="/completed_stores/" element={<CompletedStoresList />}>
              <Route path="api_error/" element={<APIErrorModal />} />
            </Route>
            <Route
              path="/completed_store/:storeId/"
              element={<CompletedStoreDetail />}
            >
              <Route path="api_error/" element={<APIErrorModal />} />
            </Route> */}
        {/* </Route> */}
        {/* </Route> */}

        {/* catch all */}
        <Route path="*" element={<Missing />} />
      </Route>
    </Routes>
  );
}

export default App;
