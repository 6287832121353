const Colors = {
  primary100: "#F7F6DC", // Bright Beige
  primary200: "#FCFCE0", // more Bright Beige, ivory
  primary300: "#FFC090", // whitish orange
  primary500: "#7FB77E", // Dark Sea Green
  primary700: "#FDFFFC", //  slightly dark white
  primary800: "#B1D7B4", //  Turqoise Green
  primary900: "#F37C13", // Princeton Orange
  primary1000: "#5B8A5A", // Added for dark  Green text
  brightBeige: "#F7F6DC",
  brighterBeige: "#FCFCE0",
  whitishOrange: "#FFC090",
  darkSeaGreen: "#7FB77E",
  paleGray: "#F9F9F9",
  turqoiseGreen: "#B1D7B4",
  princetonOrange: "#F37C13",
  iconColor: "#acacac",
  itemHover: "#f2f2f2",
  /* new colors */
  green$2: "#F2F0E4",
  green$1: "#C6D6AF",
  green0: "#7FB77E",
  green_1: "#5B7D6E",
  green_2: "#364446",
  orange$2: "#FFEBCC",
  orange$1: "#FFB967",
  orange0: "#F37C13",
  orange_1: "#9E5019",
  orange_2: "#542B15",
  gray$2: "#F5F5F5",
  gray$1: "#BEBEBE",
  gray0: "#787878",
  gray_1: "#595959",
  gray_2: "#3A3A3A",
  error100: "#fcdcbf",
  error500: "#f37c13",
  whitishGray: "#acacac",
  red: "#FF0000",
  white: "#FFFFFF",
  black: "#000000",
  gray: "#F1F1F1",

  /* Beige: "#F7F6DC", gray */
  BeigeGray: "#F6F4E9",
};

export default Colors;
