/* eslint-disable  */
import { createContext, useReducer, useMemo } from 'react';

export const StoresContext = createContext({
  stores: [],
  completedStores: [],
  addStore: ({ description, amount, date }) => {}, // eslint-disable-line
  addGroceries: ({ storeId, groceriesList }) => {}, // eslint-disable-line
  updateGrocery: (storeId, grocery) => {}, // eslint-disable-line
  deleteGrocery: ({ storeId, groceryId }) => {}, // eslint-disable-line
  setStores: (stores) => {}, // eslint-disable-line
  setCompletedStores: (stores) => {}, // eslint-disable-line
  deleteStore: (id) => {}, // eslint-disable-line
  updateStore: (id, { description, amount, date }) => {}, // eslint-disable-line
  initStores: () => {}, // eslint-disable-line
  setStoreCompletion: (storeId, isStoreCompleted) => {}, // eslint-disable-line
});

const storesReducer = (state, action) => {
  let updatableStoreIndex;
  let deleteGroceryIndex;
  let updatableStore;
  let updatedStore;
  let updatedStores;
  let inverted;

  switch (action.type) {
    case 'ADD_STORE':
      // if the store id already exsisted, return the state without change
      if (state.find((store) => store.id === action.payload.id)) {
        return state;
      }
      return [action.payload, ...state];
    case 'ADD_GROCERIES':
      updatableStoreIndex = state.findIndex(
        (store) => Number(store.id) === Number(action.payload.storeId)
      );
      updatableStore = state[updatableStoreIndex];
      updatedStore = { ...updatableStore };
      updatedStore.groceries.splice(0, updatedStore.groceries.length);
      action.payload.groceriesList.forEach((grocery) => {
        updatedStore.groceries.push(grocery);
      });
      updatedStores = [...state];
      updatedStores[updatableStoreIndex] = updatedStore;
      return updatedStores;
    case 'SET':
      inverted = action.payload.reverse();
      return inverted;
    case 'UPDATE':
      updatableStoreIndex = state.findIndex((store) => store.id === action.payload.id);
      updatableStore = state[updatableStoreIndex];
      // updatedItem = {...updatableStore, ...action.payload.data};
      updatedStores = [...state];
      updatedStores[updatableStoreIndex] = action.payload.data;
      return updatedStores;
    case 'DELETE_STORE':
      return state.filter((store) => store.id !== action.payload);
    case 'DELETE_GROCERY':
      updatableStoreIndex = state.findIndex((store) => store.id === action.payload.storeId);
      updatableStore = state[updatableStoreIndex];
      updatedStore = { ...updatableStore };
      deleteGroceryIndex = updatedStore.groceries.findIndex(
        (grocery) => grocery.id === action.payload.groceryId
      );
      if (deleteGroceryIndex > -1) {
        updatedStore.groceries.splice(deleteGroceryIndex, 1);
      }
      updatedStores = [...state];
      updatedStores[updatableStoreIndex] = updatedStore;
      return updatedStores;
    case 'UPDATE_GROCERY':
      let groceryIndex;
      updatableStoreIndex = state.findIndex((store) => store.id === action.payload.storeId);
      updatableStore = state[updatableStoreIndex];
      // copy old store data before change the data
      updatedStore = { ...updatableStore };
      groceryIndex = updatedStore.groceries.findIndex(
        (grocery) => grocery.id === action.payload.grocery.id
      );
      // set new grocery data to NewStoreData
      updatedStore.groceries[groceryIndex] = action.payload.grocery;

      updatedStores = [...state];
      updatedStores[updatableStoreIndex] = updatedStore;
      return updatedStores;
    case 'INIT':
      return [];
    case 'SET_STORE_COMPLETION':
      updatableStoreIndex = state.findIndex((store) => store.id === action.payload.storeId);
      updatableStore = state[updatableStoreIndex];
      // updatedItem = {...updatableStore, ...action.payload.data};
      updatedStores = [...state];
      updatedStores[updatableStoreIndex] = {
        ...updatableStore,
        is_completed: action.payload.isStoreCompleted,
      };
    default:
      return state;
  }
};

function completedStoresReducer(state, action) {
  switch (action.type) {
    case 'SET':
      const inverted = action.payload.reverse();
      return inverted;
    default:
      return state;
  }
}

function StoresContextProvider({ children }) {
  const [storesState, dispatch] = useReducer(storesReducer, []);
  const [completedStoresState, completedStoresdispatch] = useReducer(completedStoresReducer, []);

  function addStore(storeData) {
    dispatch({ type: 'ADD_STORE', payload: storeData });
  }

  function addGroceries(storeId, groceriesList) {
    dispatch({
      type: 'ADD_GROCERIES',
      payload: { storeId, groceriesList },
    });
  }

  function setStores(stores) {
    dispatch({ type: 'SET', payload: stores });
  }

  function deleteStore(id) {
    dispatch({ type: 'DELETE_STORE', payload: id });
  }

  function updateGrocery(storeId, grocery) {
    dispatch({ type: 'UPDATE_GROCERY', payload: { storeId: storeId, grocery: grocery } });
  }

  function deleteGrocery(storeId, groceryId) {
    dispatch({ type: 'DELETE_GROCERY', payload: { storeId, groceryId } });
  }

  function updateStore(id, storeData) {
    dispatch({ type: 'UPDATE', payload: { id, data: storeData } });
  }

  function initStores() {
    dispatch({ type: 'INIT', payload: [] });
  }

  function setStoreCompletion(storeId, isStoreCompleted) {
    dispatch({
      type: 'SET_STORE_COMPLETION',
      payload: { storeId: storeId, isStoreCompleted: isStoreCompleted },
    });
  }

  function setCompletedStores(stores) {
    completedStoresdispatch({ type: 'SET', payload: stores });
  }

  const value = useMemo(
    () => ({
      stores: storesState,
      completedStores: completedStoresState,
      setStores,
      addStore,
      addGroceries,
      deleteStore,
      deleteGrocery,
      updateGrocery,
      updateStore,
      initStores,
      setStoreCompletion: setStoreCompletion,
      setCompletedStores,
    }),
    [storesState]
  );

  return <StoresContext.Provider value={value}>{children}</StoresContext.Provider>;
}

export default StoresContextProvider;
