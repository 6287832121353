import { createContext, useReducer, useMemo, useState } from 'react';

export const FriendsContext = createContext({
  friends: [],
  openRequests: [],
  setOpenRequests: (openrequests) => {}, // eslint-disable-line
  initOpenRequests: () => {}, //eslint-disable-line
  addFriend: ({ id, username, profile_image, friend_status }) => {}, // eslint-disable-line
  setFriends: (friends) => {}, // eslint-disable-line
  deleteFriend: (id) => {}, // eslint-disable-line
  initFriends: () => {}, //eslint-disable-line
  isFriendsInitialized: false,
});

const friendsReducer = (state, action) => {
  let inverted;

  switch (action.type) {
    case 'ADD_FRIEND':
      return [action.payload, ...state];
    case 'SET':
      inverted = action.payload.reverse();
      return inverted;
    case 'DELETE_FRIEND':
      return state.filter((friend) => friend.id !== action.payload);
    case 'INIT':
      return [];
    default:
      return state;
  }
};

const openRequestReducer = (state, action) => {
  switch (action.type) {
    case 'ADD_OPEN_REQUEST':
      return [action.payload, ...state];
    case 'SET_OPEN_REQUEST':
      return action.payload;
    case 'DELETE_OPEN_REQUEST':
      return state.filter((friend) => friend.id !== action.payload);
    case 'INIT_OPEN_REQUEST':
      return [];
    default:
      return state;
  }
};

function FriendsContextProvider({ children }) {
  const [friendsState, dispatch] = useReducer(friendsReducer, []);
  const [openRequestsState, openRequestDispatch] = useReducer(openRequestReducer, []);
  const [isFriendsInitialized, setIsFriendsInitialized] = useState(false);

  function addFriend(friendData) {
    dispatch({ type: 'ADD_FRIEND', payload: friendData });
  }

  function setFriends(friends) {
    setIsFriendsInitialized(true);
    dispatch({ type: 'SET', payload: friends });
  }

  function deleteFriend(id) {
    dispatch({ type: 'DELETE_FRIEND', payload: id });
  }

  function initFriends() {
    setIsFriendsInitialized(false);
    dispatch({ type: 'INIT', payload: [] });
  }

  function addOpenRequest(openRequestData) {
    openRequestDispatch({ type: 'ADD_OPEN_REQUEST', payload: openRequestData });
  }

  function setOpenRequests(openRequests) {
    openRequestDispatch({ type: 'SET_OPEN_REQUEST', payload: openRequests });
  }

  function deleteOpenRequest(id) {
    openRequestDispatch({ type: 'DELETE_OPEN_REQUEST', payload: id });
  }

  function initOpenRequests() {
    setOpenRequests({ type: 'INIT_OPEN_REQUEST', payload: [] });
  }

  const value = useMemo(
    () => ({
      friends: friendsState,
      setFriends,
      addFriend,
      deleteFriend,
      initFriends,
      isFriendsInitialized,
      openRequests: openRequestsState,
      addOpenRequest,
      deleteOpenRequest,
      setOpenRequests,
      initOpenRequests,
    }),
    [friendsState, isFriendsInitialized, openRequestsState]
  );

  return <FriendsContext.Provider value={value}>{children}</FriendsContext.Provider>;
}

export default FriendsContextProvider;
